import React from 'react'
import Layout from '../components/layout/'
import Callout from '../components/callout/'
import PageWrapper from '../components/pageWrapper'
import Header from '../components/header'
import styles from './styles/about.module.css'

const About = ({ data }) => {
  const stats = data.stats.edges[0].node

  return (
    <Layout callout={<Callout />}>
      <Header stats={stats} />
      <PageWrapper>
        <div className={styles.contentBlock}>
          <h1>{data.allContentfulAboutUs.edges[0].node.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.allContentfulAboutUs.edges[0].node.body.childMarkdownRemark
                  .html,
            }}
          />
        </div>
        <section>
          <div className={styles.contentBlock}>
            <h1>How It Works</h1>
            <p>Grand Junction Imagination Library:</p>
            <br />
            <ul>
              <li>Raises money to fund this program locally</li>
              <li>
                Registers local children to receive monthly books at no cost to
                their family
              </li>
              <li>
                Purchases the books through our partnership with Dolly Parton
                Imagination Library, which mails books to registered children
              </li>
            </ul>
            <br />
            <p>
              ALL children ages 4 and under, with mailing addresses in the Grand
              Junction service area are encouraged to register. The Grand
              Junction service area currently includes mailing addresses in the
              following zip codes: 81501, 81503, 81504, 81505, 81506, and 81507.
            </p>
          </div>
        </section>
      </PageWrapper>
    </Layout>
  )
}

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    stats: allContentfulStats {
      edges {
        node {
          id
          booksSent
          kidsRegistered
        }
      }
    }
    allContentfulAboutUs {
      edges {
        node {
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default About

import React from 'react'
import styles from './header.module.css'
import Icon from '../../components/icons/icon'
import classNames from 'classnames'
import numeral from 'numeral'

const Header = ({ stats, backgroundImage }) => {
  return (
    <div
      className={classNames([
        styles.headerWrapper,
        backgroundImage && styles.headerWrapperImage,
      ])}
      style={{
        backgroundImage: backgroundImage ? ` url(${backgroundImage}) ` : null,
      }}
    >
      <div className={styles.headerFooter}>
        <div className={styles.headerFooterInner}>
          <div className={styles.stat}>
            <Icon icon="Book" className={styles.statIcon} />
            {stats && (
              <span>{numeral(stats.booksSent).format(0, 0)} Books Sent</span>
            )}
          </div>
          <div className={styles.stat}>
            <Icon icon="Children" className={styles.statIcon} />
            {stats && (
              <span>
                {numeral(stats.kidsRegistered).format(0, 0)} Kids Registered
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

import React, { Component } from 'react'
import { Link } from 'gatsby'
import Button from '../button'
import styles from './callout.module.css'

class Callout extends Component {
  render() {
    return (
      <div className={styles.calloutWrapper}>
        <div className={styles.callout}>
          <h4 className={styles.calloutText}>The Gift of Books</h4>
          <div className={styles.buttonRow}>
            <a
              href="https://imaginationlibrary.com/check-availability/#selectedCountry=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Register your Child</Button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Callout
